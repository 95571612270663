import React from 'react'
import styled from 'styled-components'
import { AppVideoBanner } from './AppVideoBanner'
import PayablesBannerData from '../Data/PayablesBannerData.json'

const AppPayablesBannerWapper = styled.div`
  .PayablescontainSection {
    /* background-color: #021d2d; */
    display: flex;
    max-width: 1920px;
    margin: auto;
    padding: 130px 30px 50px 30px;
    justify-content: space-between;
    overflow: hidden;
    @media (max-width: 1391px) {
      padding: 130px 20px 50px 20px;
    }
    @media (max-width: 1090px) {
      padding: 80px 15px;
      justify-content: center;
      flex-wrap: wrap;
    }
  }

  .PayablesBannerSectionOne {
    margin-top: 2rem;
    margin-bottom: auto;
    @media (max-width: 1390px) {
      margin-top: auto;
    }
  }
  .PayablesBannerHeadingSize {
    /* color: #ffffff; */
    max-width: 780px;
    line-height: 1.30435em;
    padding-bottom: 3%;
    font-size: 2.5rem;
    margin-top: auto;
  }
  .PayablesBannerPara {
    /* color: #ffffff; */
    max-width: 950px;
    padding: 10px 20px 0px 0px;
  }
  .PayablesBannerImage {
    width: 100%;
    height: auto;
    // float: right;
    // @media (max-width: 1390px) {
    //   width: 100%;
    //   float: unset;
    // }
  }
  .whyRaindropListView {
    display: none;
  }
  .PayablesSectionButton {
    display: flex;
    justify-content: center;
    margin: 40px 0px 20px 0px;
  }
  .PayablesBannervideo {
    width: 65%;
    height: auto;
    @media (max-width: 1650px) {
      margin-top: 3%;
    }
    @media (max-width: 1390px) {
      width: 100%;
      margin-top: unset;
    }
  }
  .PayablesBannerContent {
    margin-top: 3%;
    @media (max-width: 1650px) {
      margin-top: unset;
    }
  }
`

export const AppPayablesBanner = () => {
  return (
    <AppPayablesBannerWapper>
      {PayablesBannerData.map((data, index) => {
        return (
          <div key={index}>
            <AppVideoBanner
              containSection="PayablescontainSection"
              EventBannerHeading={data.heading}
              paraOne={data.paraOne}
              paraTwo={data.paraTwo}
              paraThree={data.paraThree}
              ImagePath={data.ImagePath}
              altName={data.AltName}
              ButtonStyle="PayablesSectionButton"
              BannerSectionOne="PayablesBannerSectionOne"
              BannerSectionTwo="BannerSectionTwo item-2"
              EventBannerHeadingSize="PayablesBannerHeadingSize"
              EventBannerPara="PayablesBannerPara"
              EventBannerImage="PayablesBannerImage"
              UlStyle="whyRaindropListView"
              EventBannerSectionTwo="PayablesBannervideo"
              EventBannerSectionOne="PayablesBannerContent"
            />
          </div>
        )
      })}
    </AppPayablesBannerWapper>
  )
}
