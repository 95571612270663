import React from 'react'
import styled from 'styled-components'
// import { ReUsePtag } from './ReUsePtag'
import { ReUseHTwotag } from './ReUseHTwoTag'

const AppPayableHoverSidebarWapper = styled.div`
  max-width: 1920px;
  height: 100vh;
  margin: auto;
  scroll-behavior: smooth;
  overflow: hidden;
  background-color: #102c64;
  width: 96vw;
  border-radius: 42px;
  margin-top: 52px;
  @media (min-width: 1650px) {
    height: 75vh;
  }
  .PayableManagementHeading {
    margin: 0;
    text-align: center;
    padding: 40px 0px 100px 0px;
    color: #fff;
  }
  .tab {
    float: left;
    /* border: 1px solid #ccc; */
    /* background-color: #f1f1f1; */
    width: 40vw;
    height: auto;
    padding-left: 50px;
    margin-bottom: 24px;

    @media (min-width: 1920px) {
      width: 35vw;
      padding-left: 124px;
    }
    @media (max-width: 440px) {
      /* padding-left: 4px; */
      padding: 0;
    }
  }

  /* Style the buttons inside the tab */
  .tab button {
    margin-bottom: 12px;
    display: block;
    background-color: #fff;
    color: #047cc2;
    padding: 22px 16px;
    width: 100%;
    border: none;
    outline: none;
    text-align: left;
    cursor: pointer;
    font-size: 21px;
    font-weight: bold;
    transition: 0.5s;
    /* box-shadow: 8px 8px 10px 0 rgb(0 0 0 / 10%); */
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    @media (max-width: 1090px) {
      font-size: 18px;
    }
    @media (max-width: 825px) {
      font-size: 14px;
    }
    @media (max-width: 440px) {
      font-size: 12px;
      padding-left: 4px;
      margin: 0;
    }
  }

  /* Change background color of buttons on hover */
  .tab button:hover {
    color: #64bc12;
    box-shadow: 2px 4px 10px 0 rgb(0 0 0 / 10%);
    font-size: 22px;
    border-left: 5px solid #64bc12;
    @media (max-width: 440px) {
      font-size: 13px;
    }
    @media (max-width: 825px) {
      font-size: 13px;
    }
    @media (max-width: 1034px) {
      font-size: 17px;
    }
  }

  /* Create an active/current "tab button" class */
  .tab button.active {
    color: #64bc12;
    box-shadow: 2px 4px 10px 0 rgb(0 0 0 / 10%);
    font-size: 22px;
    border-left: 6px solid #64bc12;
    @media (max-width: 1090px) {
      font-size: 17px;
    }
  }

  /* Style the tab content */
  .tabcontent {
    float: left;
    padding: 0px 0px 0px 80px;
    /* border: 1px solid #ccc; */
    width: 60vw;
    border-left: none;
    height: 100vh;
    display: block;
    @media (max-width: 440px) {
      margin: 0;
      padding: 4px;
    }
    @media (min-width: 1920px) {
      width: 43vw;
      padding-left: 124px;
    }
  }

  /* Clear floats after the tab */
  .clearfix::after {
    content: '';
    clear: both;
    display: table;
  }
  .Sidebar-imageOne {
    width: 15vw;
    height: auto;
    display: block;
    margin-left: 160px;
    margin-right: auto;
    @media (max-width: 440px) {
      margin-left: 80px;
    }
    @media (min-width: 2560px) {
      width: 9vw;
      height: auto;
    }
    @media (min-width: 1920px) {
      width: 11vw;
      height: auto;
    }
  }
  .SideBar-points {
    margin: 20px 50px 0px 0px;
    font-size: 18px;
    color: #fff;
    @media (max-width: 1090px) {
      font-size: 16px;
    }
    @media (max-width: 825px) {
      font-size: 14px;
    }
    @media (max-width: 440px) {
      font-size: 12px;
      margin: 4px;
    }
  }
  .Sidebar-icons {
    width: 20px;
    height: auto;
    padding: 0px 5px 0px 0px;
  }
  .RightSidetabcontent {
    width: 100vw;
  }
  .tablinks {
    border-radius: 50px;
  }
`

export const AppPayableHoverSidebar = () => {
  function openCity(evt: React.MouseEvent<HTMLButtonElement, MouseEvent>, cityName: string) {
    var i, tabcontent, tablinks
    tabcontent = document.getElementsByClassName('tabcontent') as HTMLCollectionOf<HTMLElement>
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = 'none'
    }
    tablinks = document.getElementsByClassName('tablinks')
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(' active', '')
    }
    const cityElement = document.getElementById(cityName)
    if (cityElement) {
      cityElement.style.display = 'block'
    }
    evt.currentTarget.className += ' active'
  }

  return (
    <AppPayableHoverSidebarWapper>
      <ReUseHTwotag
        Heading="Drive More Efficiency and Productivity with AP Automation"
        HeadingStyle="PayableManagementHeading"
      />
      <div className="SideBarContainer">
        <div className="tab">
          <button className="butn tablinks active" onMouseOver={(event) => openCity(event, 'AI/MLPoweredPayables')}>
            AI-Powered Payables Efficiency & Scalability
          </button>
          <button className="tablinks" onMouseOver={(event) => openCity(event, 'TouchlessInvoice')}>
            Touchless Invoice Processing
          </button>
          <button className="tablinks" onMouseOver={(event) => openCity(event, 'ReduceRisk')}>
            Reduce Risk and Improve Controls
          </button>
          <button className="tablinks" onMouseOver={(event) => openCity(event, 'RapidlyConnect')}>
            Rapidly Connect to Popular ERP Systems
          </button>
          <button className="tablinks" onMouseOver={(event) => openCity(event, 'VisibilityAcross')}>
            Visibility Across Entire Process
          </button>
          <button className="tablinks" onMouseOver={(event) => openCity(event, 'AutomatedWay')}>
            Automated 2 and 3 Way Matching
          </button>
        </div>
        <div className="RightSidetabcontent">
          <div id="AI/MLPoweredPayables" className="FirstSlide tabcontent">
            <img src="/img/AI_ML.png" className="Sidebar-imageOne" />
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              AI-enabled invoice processing productivity acceleration to enterprise scale
            </p>
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              Adapt unstructured invoices into digital standardized formats
            </p>
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              Drive policy compliance to directly reduce fraud and risk exposure
            </p>
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              Free your AP Resources from redundant and low-value tasks
            </p>
          </div>
          <div id="TouchlessInvoice" className="tabcontent">
            <img src="/img/payable_toucless.png" className="Sidebar-imageOne" />
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              Straight through approval process for all compliant invoices
            </p>
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              Save money through Early Payment Discounts
            </p>
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              Reconcile line item allocations across multiple cost centers
            </p>
          </div>

          <div id="ReduceRisk" className="tabcontent">
            <img src="/img/payable-reduce-risk.png" className="Sidebar-imageOne" />
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              Eliminate fraudulent and duplicate payments
            </p>
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              Eliminate payments to unapproved or former suppliers
            </p>
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              Monitor on-going third-party risk factors
            </p>
          </div>
          <div id="RapidlyConnect" className="tabcontent">
            <img src="/img/payable-erp.png" className="Sidebar-imageOne" />
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              Seamlessly share GL and transaction data with financial systems
            </p>
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              Modern JSON Webhooks and REST-API connections
            </p>
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              OK-to-Pay notification to ERP or ACH payments directly from Raindrop
            </p>
          </div>

          <div id="VisibilityAcross" className="tabcontent">
            <img src="/img/payable-realtime.png" className="Sidebar-imageOne" />
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              Real-time intelligence into all aspects of AP process and productivity
            </p>
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              Suppliers enjoy real-time status of payment timing
            </p>
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              360 degree views into Spend, Contracts, Supplier Performance and Budgets
            </p>
          </div>

          <div id="AutomatedWay" className="tabcontent">
            <img src="/img/2-3way-coding.png" className="Sidebar-imageOne" />
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              Easily Configured Approval Workflows for PO, Goods Receipts, Invoices{' '}
            </p>
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              Manage PO, non-PO and Recurring Spend Categories
            </p>
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              Complete Control to Modify and Update Process Flows in Minutes
            </p>
          </div>
        </div>
      </div>
      <div className="clearfix"></div>
    </AppPayableHoverSidebarWapper>
  )
}
