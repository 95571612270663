import React, { useEffect } from 'react'
import { Layout } from '../../components/AppLayout'
import styled from 'styled-components'
import { AppPayablesBanner } from '../../components/AppPayablesBanner'

import Scroll from 'react-scroll'
import { RDHelmet } from '../../components/RDHelmet'
import { AppPayablesSecondSection } from '../../components/AppPayablesSecondSection'

// import { AppPayablesCards } from '../../components/AppPayablesCards'
// import { AppPayableSideBarMenu } from '../../components/AppPayableSideBarMenu'
import { AppPayableHoverSidebar } from '../../components/AppPayableHoverSidebar'
import { AppHomeBookDemo } from '../../components/AppHomeBookDemo'

var scroll = Scroll.animateScroll

const scrollData = {
  ByCompany: 700,
  FreeInstance: 900,
  SmallEnterprise: 1500,
  MediumEnterprise: 3000,
  LargeEnterprise: 4300,
  ByRoles: 5700,
  Executives: 5900,
  Procurement: 7400,
  Finance: 8600,
}

const scrollToIndex = (location: string) => {
  const item = Object.entries(scrollData).find((item) => item[0] == location)
  if (item && item[1]) {
    scroll.scrollTo(item[1])
  }
}

const PayablesWrapper = styled.div`
  body: {
    overflow-x: hidden;
    overflow-y: scroll;
  }
`

const Payables = (props: any) => {
  const schemaMarkup = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Raindrop Systems Inc',
    alternateName: 'Raindrop Systems Inc',
    url: 'https://raindrop.com/',
    logo: 'https://storage.googleapis.com/raindroppublic/website_data/raindrop_icon.svg',
    description:
      'Raindrop is the modern AI-Powered Source-to-Pay SaaS Enterprise Spend Management Full-Suite Modular Platform. Raindrop offers an AI-enabled enterprise cloud based spend management software focused on cost savings. Get started with free tools, and upgrade as you grow.',
    image: 'https://storage.googleapis.com/raindroppublic/website_data/OurServices.jpg',
    email: 'info@raindrop.com',
    contactPoint: [
      { '@type': 'ContactPoint', telephone: '408-352-5162', contactType: 'Business', areaServed: 'United States' },
    ],
    address: {
      '@type': 'Corporate',
      addressLocality: ' 226 Airport Parkway Suite 250',
      addressRegion: ' San Jose, California 95110',
      postalCode: '95110',
      addressCountry: 'United States',
    },
    sameAs: [
      'https://www.facebook.com/Raindrop-Systems-Inc-103810868375793',
      'https://twitter.com/saas_raindrop',
      'https://www.instagram.com/raindrop_spendmanagement/?igshid=1mn6sbd70aybs',
      'https://www.linkedin.com/company/raindropsystemsinc/',
    ],
  }
  useEffect(() => {
    if (props.location.state && props.location.state.scroll) {
      scrollToIndex(props.location.state.scroll)
    }
  }, [])

  return (
    <Layout scrollToIndex={scrollToIndex}>
      <RDHelmet
        subtitle={'Payables'}
        contentDescription="Streamline accounts payable with Raindrop: automate invoices, enhance visibility, and drive efficiency effortlessly."
        contentKeywords="content='Accounts Payable Automation, Invoice Processing Efficiency, Payment Optimization Tools, Finance Team Collaboration, Cost Control Management, Supplier Payment Compliance, Scalable Payables Solutions, Spend Visibility Analytics, Streamlined Workflow Integration, Risk Mitigation Features'"
        contentOG="AI Powered Source-to-Pay SaaS Platform"
        contentOgDescription="Streamline accounts payable with Raindrop: automate invoices, enhance visibility, and drive efficiency effortlessly."
        contentOgUrl="https://raindrop.com/solutions/Payables/"
        schemaMarkup={schemaMarkup}
      />
      <PayablesWrapper>
        <AppPayablesBanner />
        <AppPayablesSecondSection />
        <AppPayableHoverSidebar />
        {/* <AppPayableSideBarMenu /> */}
        {/* <AppPayablesCards /> */}
        <AppHomeBookDemo />
      </PayablesWrapper>
    </Layout>
  )
}

export default Payables
